import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardSelectDoctorOrderNoteUX = (props: any) => {
    return(
      <div
        id="CardSelectDoctorOrderNoteUX-div-0"
        style={{width: "100%", height: "100%"}}>
        <Form
          id="CardSelectDoctorOrderNoteUX-Form-1">
          <FormGroup
            id="CardSelectDoctorOrderNoteUX-FormGroup-2"
            inline={true}>
            <FormField
              id="CardSelectDoctorOrderNoteUX-FormField-4"
              inline={true}>
              <label
                id="CardSelectDoctorOrderNoteUX-label-17">
                {props.intl?.formatMessage({ id: "วันที่" })}
              </label>
            </FormField>
            <FormField
              id="CardSelectDoctorOrderNoteUX-FormField-5"
              inline={true}
              width={3}>
              <div
                id="CardSelectDoctorOrderNoteUX-div-19"
                style={{ width: "100%" }}>
                
                <DateTextBox
                  id="CardSelectDoctorOrderNoteUX-DateTextBox-22"
                  onChange={props.onChangStartDate}
                  value={props.filterDate?.startDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              id="CardSelectDoctorOrderNoteUX-FormField-6"
              inline={true}>
              <label
                id="CardSelectDoctorOrderNoteUX-label-18">
                -
              </label>
            </FormField>
            <FormField
              id="CardSelectDoctorOrderNoteUX-FormField-15"
              inline={true}
              width={3}>
              <div
                id="CardSelectDoctorOrderNoteUX-div-20"
                style={{ width: "100%" }}>
                
                <DateTextBox
                  id="CardSelectDoctorOrderNoteUX-DateTextBox-23"
                  onChange={props.onChangEndDate}
                  value={props.filterDate?.endDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              id="CardSelectDoctorOrderNoteUX-FormField-16"
              inline={true}>
              <div
                id="CardSelectDoctorOrderNoteUX-div-21">
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardSelectDoctorOrderNoteUX-FormGroup-3">
            <FormField
              id="CardSelectDoctorOrderNoteUX-FormField-8"
              inline={true}
              width={16}>
              <div
                id="CardSelectDoctorOrderNoteUX-div-12"
                style={{ width: "100%" }}>
                
                <Table
                  data={props.doctorOrderNote}
                  headers={",Order Summary Detail"}
                  id="CardSelectDoctorOrderNoteUX-Table-14"
                  keys={"chk,orderSummaryDetail"}
                  minRows={11}
                  showPagination={false}
                  style={{ height: 470  }}
                  widths="^10,*">
                </Table>
              </div>
              <div
                id="CardSelectDoctorOrderNoteUX-div-13"
                style={{ display: "flex", justifyContent: "flex-end", margin: "20px 0px" }}>
                
                <div
                  id="CardSelectDoctorOrderNoteUX-div-24">
                  {props.buttonSelect}
                </div>
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardSelectDoctorOrderNoteUX

export const screenPropsDefault = {}

/* Date Time : Wed Mar 19 2025 14:33:44 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-Form-1"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-FormGroup-2"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-FormGroup-3"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-FormField-4"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-FormField-5"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-FormField-6"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-FormField-8"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-div-12"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-div-13"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", margin: \"20px 0px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 14,
      "name": "Table",
      "parent": 12,
      "props": {
        "NoDataComponent": {
          "type": "value",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.doctorOrderNote"
        },
        "headers": {
          "type": "code",
          "value": "\",Order Summary Detail\""
        },
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-Table-14"
        },
        "keys": {
          "type": "code",
          "value": "\"chk,orderSummaryDetail\""
        },
        "minRows": {
          "type": "code",
          "value": "11"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: 470  }"
        },
        "widths": {
          "type": "value",
          "value": "^10,*"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-FormField-15"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-FormField-16"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.intl?.formatMessage({ id: \"วันที่\" })"
        },
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-label-17"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "-"
        },
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-label-18"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-div-19"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-div-20"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-div-21"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 22,
      "name": "DateTextBox",
      "parent": 19,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-DateTextBox-22"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangStartDate"
        },
        "value": {
          "type": "code",
          "value": "props.filterDate?.startDate"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 23,
      "name": "DateTextBox",
      "parent": 20,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-DateTextBox-23"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.filterDate?.endDate"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSelect"
        },
        "id": {
          "type": "value",
          "value": "CardSelectDoctorOrderNoteUX-div-24"
        }
      },
      "seq": 24,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardSelectDoctorOrderNoteUX",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
