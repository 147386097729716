import React, { useCallback, useEffect, useMemo, useState } from "react";
import CardLayout from "react-lib/apps/common/CardLayout";
import CardSelectDoctorOrderNoteUX from "./CardSelectDoctorOrderNoteUX";
import { useIntl } from "react-intl";
import moment from "moment";
import { formatDateToStringBe } from "react-lib/utils/dateUtils";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { Checkbox } from "semantic-ui-react";

const CARD_SELECT_DOCTOR_ORDER_NOTE = "CardSelectDoctorOrderNote";

type CardSelectDoctorOrderNoteProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  divisionType?: string;
  django?: any;

  onClose?: any;
  nurseNoteSelectDoctorOrderNote?: any;
  languageUX?: any;

  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
};

const toDate = moment();

const CardSelectDoctorOrderNote = (props: CardSelectDoctorOrderNoteProps) => {
  const intl = useIntl();
  // checked
  const [checked, setChecked] = useState<any>([]);
  const [filterDate, setFilterDate] = useState<any>({
    startDate: formatDateToStringBe(toDate),
    endDate: formatDateToStringBe(toDate),
  });

  useEffect(() => {
    props.onEvent({
      message: "HandleNurseNote",
      params: {
        action: "searchDoctorOrderNote",
        card: CARD_SELECT_DOCTOR_ORDER_NOTE,
        startDate: filterDate?.startDate,
        endDate: filterDate?.endDate,
      },
    });
  }, []);

  const handleChecked = (check: boolean | undefined, item: any) => {
    const ids: number[] = item.id_list || [item.id];

    if (checked.some((id: any) => ids.includes(id))) {
      setChecked(checked.filter((id: any) => !ids.includes(id)));
    } else {
      setChecked([...checked, ...ids]);
    }
  };

  const doctorOrderNote = useMemo(() => {
    return (props.nurseNoteSelectDoctorOrderNote || [])?.map((item: any) => {
      return {
        chk: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              checked={checked.includes(item.id)}
              onChange={(e, v) => {
                handleChecked(v.checked, item);
              }}
            />
          </div>
        ),
        orderSummaryDetail: (
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ color: "#2362B2" }}>วันเวลาที่ส่ง</div>
              <div style={{ margin: "0px 20px" }}>{`${item.created?.replace(/\[|\]/g, "")}`}</div>
              <div>{`(${item.co_signed?.replace(/\s*\(.*?\)/g, "")})`}</div>
            </div>
            <div style={{ margin: "10px 0px" }}>{item.detail}</div>
          </div>
        ),
      };
    });
  }, [props.nurseNoteSelectDoctorOrderNote, checked]);

  const handleSearchDoctorOrderNote = () => {
    props.onEvent({
      message: "HandleNurseNote",
      params: {
        action: "searchDoctorOrderNote",
        card: CARD_SELECT_DOCTOR_ORDER_NOTE,
        startDate: filterDate?.startDate,
        endDate: filterDate?.endDate,
      },
    });
  };

  const handleSelectDoctorOrderNote = () => {
    const nurseNoteFocus = props.nurseNoteSelectDoctorOrderNote
      .filter((item: any) => checked.includes(item.id))
      .map((item: any) => {
        return `- ${item.detail}`;
      });

    if (checked.length) {
      props.setProp("selectedNurseNote.doctor_note_order", nurseNoteFocus.join("\n"));
      props.onClose();
    } else {
      props.onClose();
    }
  };

  const handleChangeDate = (type: string) => (date: any) => {
    setFilterDate({
      ...filterDate,
      [`${type}`]: date,
    });
  };

  return (
    <div>
      <CardLayout
        closeable={true}
        toggleable={true}
        titleText="เลือกคำสั่งแพทย์"
        onClose={props.onClose}
      >
        <CardSelectDoctorOrderNoteUX
          intl={intl}
          filterDate={filterDate}
          doctorOrderNote={doctorOrderNote}
          onChangStartDate={handleChangeDate("startDate")}
          onChangEndDate={handleChangeDate("endDate")}
          buttonSearch={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSearchDoctorOrderNote}
              // data
              paramKey={`${CARD_SELECT_DOCTOR_ORDER_NOTE}_search`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SELECT_DOCTOR_ORDER_NOTE}_search`]}
              // config
              name="search"
              style={{ width: "100%" }}
              title="ค้นหา"
              color="blue"
            />
          }
          buttonSelect={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSelectDoctorOrderNote}
              // data
              paramKey={`${CARD_SELECT_DOCTOR_ORDER_NOTE}_add_option`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`${CARD_SELECT_DOCTOR_ORDER_NOTE}_add_option`]
              }
              // config
              name="add_option"
              style={{ width: "100%" }}
              title="เลือก"
              color="green"
            />
          }
        />
      </CardLayout>
    </div>
  );
};

export default CardSelectDoctorOrderNote;
